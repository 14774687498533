.mobile-datepicker {
 width: 100%;
}

.mobile-datepicker .month {
 width: 100%;
 height: 100%;
 margin-bottom: 16px;
}

.mobile-datepicker .month .month-title {
 width: 100%;
 font-size: 17px;
 font-family: "SF Pro Text", -apple-system, Roboto, Helvetica Neue, Arial, serif;
 font-weight: 600;
 line-height: 22px;
 letter-spacing: -0.4px;
 text-transform: capitalize;
}

.mobile-datepicker .month .month-title:before,
.mobile-datepicker .month .month-title:after {
 content: " ";
 display: table;
}

.mobile-datepicker .month .month-title:after {
 clear: both;
}

.mobile-datepicker .month .month-title span {
 display: block;
}

.mobile-datepicker .month ul {
 padding-left: 0;
 list-style: none;
 margin: 0 -2px;
}

.mobile-datepicker .month ul:before,
.mobile-datepicker .month ul:after {
 content: " ";
 display: table;
}

.mobile-datepicker .month ul li {
 float: left !important;
 text-align: center;
 width: 14.28%;
}

.mobile-datepicker .month ul:after {
 clear: both;
}

.mobile-datepicker .month ul.days {
 margin-bottom: 0;
}

.mobile-datepicker .month ul.days li {
 float: left !important;
 width: 14.28%;
 height: 30px;
 color: #818C99;
 display: flex;
 font-size: 12px;
 text-align: center;
 align-items: center;
 font-family: "SF Pro Display", -apple-system, Roboto, Helvetica Neue, Arial, serif;
 font-weight: 400;
 line-height: 14px;
 letter-spacing: 0;
 justify-content: center;
 text-transform: lowercase;
}

.mobile-datepicker .month ul.date li {
 font-size: 16px;
 line-height: 1.125;
 letter-spacing: -0.26px;
 font-weight: 300;
 height: 41px;
 cursor: pointer;
}

.mobile-datepicker .month ul.date li span {
 display: inline-flex;
 align-items: center;
 justify-content: center;
 height: 35px;
 width: 35px;
 padding: 3px;
 border-radius: 100%;
}

.mobile-datepicker .month ul.date li span img {
 width: 30px;
 height: 30px;
 opacity: 0.6;
 filter: grayscale(100%);
}

.mobile-datepicker .month ul.date li span:hover,
.mobile-datepicker .month ul.date li span:focus {
 text-decoration: none;
 cursor: pointer;
}

.mobile-datepicker .month ul.date li.active span {
 border-radius: 9px;
 height: 32px;
 width: 32px;
}

.mobile-datepicker .month ul.date li.active span img {}

.mobile-datepicker .month ul.date li.disabled {
 pointer-events: none;
}

.mobile-datepicker .month ul.date li.disabled span {
 cursor: not-allowed;
}

.mobile-datepicker .month ul.date li.disabled span img {
 opacity: 0.2;
 filter: grayscale(100%);
}

.mobile-datepicker .month ul.date li.visible-hidden {
 opacity: 0;
 filter: alpha(opacity=1);
 visibility: hidden;
 pointer-events: none;
 height: 41px;
}

.dayChecked span {}

.dayChecked span img {
 opacity: 1 !important;
 filter: grayscale(0%) !important;;
 
}


